import './App.css';
import Content from './components/Content';
import HeroPage from './components/HeroPage';
import Footer from './components/Footer';
import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import Discount from './components/Discount';
import SubscribePage from './components/SubscribePage';
import BlankPage from './components/BlankPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route index element={<LandingPage/>}></Route>
        <Route path='/' element={<LandingPage/>}></Route>
        <Route path='/option1' element={<Discount/>}></Route>
        <Route path='/option2' element={<SubscribePage/>}></Route>
        <Route path='/blank' element={<BlankPage/>}></Route>
      </Routes> 
    </Router>
  );
}

export default App;
