import React, {useEffect } from 'react'
import people from './images/icon-people.svg'
import cash from './images/icon-cash.png'
import time from './images/icon-time.svg'
import play from './images/icon-play.svg'
import analysis from './images/Analysis2.png'
import Manalysis from './images/Analysis3.png'
import chart from './images/Chart.svg'
import charts from './images/chart1.svg'
import moon from "./images/moon.png"
import limbo from "./images/Limbo.png"
import love from "./images/love.png"
import robot from './images/robot.png'
import oneTwo from "./images/Group1-2.png";
import protect from "./images/a&e.svg";
import "@fontsource/poppins";
import check from "./images/check.svg";
import { Link } from "react-router-dom";
import tick from "./images/tick.svg";
import { HashLink } from "react-router-hash-link";
import Signup from './Signup'
import Aos from 'aos';
import 'aos/dist/aos.css';
import num from "./images/num.svg";
import numtwo from "./images/num2.svg";
const Content = () => {
    useEffect(() => {
        Aos.init({duration:2000,once:true});
      }) 
  return (
    <div className=''>
        <div className='page max-sm:hidden'>
            <div className=' h-[90%] w-full px-5 py-5 border-transparent bg-[#0F0D24] max-md:h-auto max-lg:px-[1px] max-lg:py-3 max-lg:h-auto' data-aos="flip-up">
                <div className=' rounded-lg flex justify-between max-md:h-[62vh] max-md:grid max-lg:h-[62vh] max-lg:grid py-3 max-lg:w-full max-lg:grid max-lg:justify-center'>
                     <div className='w-[40%] pt-[3.5%] h-[20%] absolute max-sm:hidden max-lg:w-full max-md:w-full max-md:pt-[50%] max-lg:pt-[50%]'>
                         <img src={robot} className=' ml-2 absolute max-lg:m-0 max-lg:w-[42%] max-lg:mx-auto max-lg:relative max-md:m-0 max-md:w-[42%] max-md:mx-auto max-md:relative' ></img>
                     </div>
                     <div className='w-[40%] ml-[60%] max-sm:hidden max-md:border-2 max-md:w-[100%] max-lg:w-full text-center max-md:ml-0 max-lg:m-0'>
                         <h1 className=' font-poppin font-semibold text-[35px] text-[#FFFFFF] leading-normal'>Sign up today to<br></br>get free access</h1>
                         <p className=' font-poppin text-[19px] mt-4 text-[#EFF5F6]'>Get real-time access to compensation market<br></br>data, learn how talent is thinking about your<br></br>brand and receive undeniable social proof</p>
                         <div className='mt-5'>
                             <HashLink to='#signup' smooth><button className=' group bg-[#EFF5F6] overflow-hidden w-[140px] h-14 rounded-lg text-center font-bold text-[#0F0D24] text-[18px] cursor-pointer relative ease-in-out shadow-lg shadow-blue-500/50 font-poppin hover:text-white
                             '><div class="absolute inset-0 w-0 bg-[#3989E8] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                             <p className='relative '>Get Started</p>
                             </button></HashLink>
                         </div>
                    </div>
                 </div>
            </div>
        </div>
        {/* mobile page */}
        <div className='pages sm:hidden'>
            <div className='border-transparent bg-[#0F0D24] h-[60%] rounded-lg px-3 max-lg:h-auto w-full grid justify-center' data-aos="flip-up">
                <div className='w-full mt-[3.0rem] flex flex-col justify-center'>
                         <h1 className=' font-poppin font-semibold text-[25px] text-center text-[#FFFFFF] leading-normal'>Sign up today to<br></br>get free access</h1>
                        <p className=' font-poppin text-[11px] mt-4 text-center text-[#EFF5F6]'>Get real-time access to compensation market<br></br>data, learn how talent is thinking about your<br></br>brand and receive undeniable social proof</p>
                    <div className='mt-5 flex justify-center'>
                            <HashLink to='#signup' smooth><button className=' group bg-[#EFF5F6] overflow-hidden w-[140px] h-14 rounded-lg text-center font-bold text-[#0F0D24] text-[18px] cursor-pointer relative ease-in-out shadow-lg shadow-blue-500/50 font-poppin hover:text-white
                            '><div class="absolute inset-0 w-0 bg-[#3989E8] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                            <p className='relative '>Get Started</p>
                        </button></HashLink>
                    </div>
               </div>
               <div className='w-full h-auto mt-[6%] relative block mb-[-5.2%]'>
                    <img src={robot} className=' w-[60%] h-auto ml-auto mr-auto'></img>
                </div>
           </div>
         </div>
          {/*--*/}
        <div className=' mt-[12%]'data-aos="fade-up">
            <div className=' flex flex-col'>
                <div className='  w-full text-center text-[#FDF9F9]'>
                       <p className=' text-[14px] max-lg:text-[16px]'>Our long-term ambition: build the leading<br></br>career ecosystem powered by web3</p>
                </div>
                <div className='w-full max-md:hidden text-center mt-4 '>
                    <h1 className='text-[19px] text-[#FDF9F9] font-bold max-lg:text-[15px]'>
                    Metaworks is better for companies. We connect
                    <br></br>directly with the minds of over a 100,000+ talents
                    </h1>
                </div>
                {/*mobile view */}
                <div className='w-full sm:hidden text-center mt-4'>
                    <h1 className='text-[23px] text-[#FDF9F9] font-bold max-lg:text-[27px]'>
                    Metaworks is better for<br></br> companies. We connect<br></br>
                    directly with the minds of over<br></br> a 100,000+ talents
                    </h1>
                </div>
                {/*--*/}
                <div className='py-[5%] px-[2%] w-full justify-between flex max-lg:grid max-lg:w-full max-lg:px-[10%] max-lg:mt-6 min-w-fit border-container-design'>
                    <div className='border-2 border-transparent py-8 px-9 w-[24%] flex flex-col h-[49%] bg-[#FDF9F9] rounded-3xl relative max-lg:w-auto max-lg:h-auto ' data-aos="fade-up" data-aos-easing="linear" >
                        <img className='w-[4rem]' src={people} ></img>
                        <h1 className=' mt-2 text-[18px] font-bold max-lg:text-[13px] max-lg:font-bold'>Grow with an unmatched community</h1>
                        <p className=' mt-2 text-[13px] max-lg:text-[11px] '>Talents are incentivized to shape<br></br>the community by sharing<br></br>their insights and to invite, vet<br></br>new talent to the community</p>
                    </div>
                    <div className=' border-2 border-transparent py-8 px-9 w-[24%] flex flex-col h-[49%] bg-[#3989E8] rounded-3xl relative max-lg:w-auto max-lg:h-auto max-lg:mt-[14%]' data-aos="fade-down" data-aos-easing="linear">
                        <img className=' w-[4rem]' src={cash}></img>
                        <h1 className=' mt-2 text-[18px] font-bold text-[#EFF5F6] max-lg:font-bold max-lg:text-[15px]'>Save money, become <br></br>smarter</h1>
                        <p className=' mt-2 text-[13px] max-lg:text-[12px] text-[#EFF5F6] max-lg:mb-7'>Our insights will save you money<br></br>and time. We even give you<br></br>recommendations. Hire and<br></br>retain the right talent.</p>
                    </div>
                    <div className=' border-2 max-sm:hidden border-transparent py-6 px-9 w-[25%] flex flex-col h-[49%] bg-[#FDF9F9] rounded-3xl relative max-lg:w-auto max-lg:h-auto max-lg:mt-[14%]' data-aos="fade-up" data-aos-easing="linear">
                        <img className='w-[4rem]' src={time} ></img>
                        <h1 className=' mt-2 text-[18px] font-bold max-lg:font-bold'>Real-time trustless <br></br>insights</h1>
                        <p className=' mt-2 text-[13px] max-lg:text-[11px] max-lg:mb-7 '>We gather compensation and culture<br></br>insights in real-time from both talents<br></br>and companies. With every new <br></br>talent joining Metaworks, your <br></br>knowledge expands</p>
                    </div> 
                    {/*mobile view */}
                    <div className='border-2 sm:hidden max-lg:grid border-transparent py-8 px-9 w-[25%] flex flex-col h-[49%] bg-[#FDF9F9] rounded-3xl relative max-lg:w-auto max-lg:h-auto max-lg:mt-[14%]' data-aos="fade-up" data-aos-easing="linear">
                        <img className='w-[4rem]' src={time} ></img>
                        <h1 className=' mt-2 text-[16px] font-bold max-lg:font-bold'>Real-time trustless <br></br>insights</h1>
                        <p className=' mt-2 max-lg:text-[10px] max-lg:mb-7 '>We gather compensation and<br></br> culture insights in real-time from<br></br> both talent and companies. With<br></br> every new talent joining Metaworks,<br></br> your knowledge expands</p>
                    </div> 
                    <div className='border-2 sm:hidden  border-transparent py-8 px-9 w-[24%] flex flex-col h-[49%] bg-[#FDF9F9] rounded-3xl relative max-lg:w-auto max-lg:h-auto max-lg:mt-[14%]' data-aos="fade-down" data-aos-easing="linear">
                        <img className='w-[4rem]' src={play} ></img>
                        <h1 className=' mt-2 text-[19px] font-bold max-lg:font-bold max-lg:text-[15px]'>Plug & play</h1>
                        <p className=' mt-2 text-[13px] max-lg:text-[12px] mb-7 max-lg:mb-7 '>We connect directly with your <br></br>HR software in minutes. Receive<br></br> support every step of the way<br></br></p>
                    </div> 
                    {/*-- */}   
                    <div className=' border-2 max-sm:hidden border-transparent py-8 px-9 w-[24%] flex flex-col h-[52%] bg-[#FDF9F9] rounded-3xl relative max-lg:w-auto max-lg:h-auto max-lg:mt-[14%]' data-aos="fade-down" data-aos-easing="linear">
                        <img className='w-[4rem]' src={play} ></img>
                        <h1 className=' mt-2 text-[18px] font-bold max-lg:font-bold max-lg:text-[15px]'>Plug & play</h1>
                        <p className=' mt-2 text-[15px] mb-10 max-lg:text-[13px] max-lg:mb-7 '>We connect directly with your <br></br>HR software in minutes. Receive<br></br>support every step of the way<br></br></p>
                    </div>
                </div>
            </div>
        </div>
        <div className='part-two max-lg:grid max-lg:w-full max-lg:px-10'>
            <div className='max-lg:hidden  w-[60%]'data-aos="zoom-in-down">
                <img className='w-[90%]' src={analysis}></img>
            </div>
            <div className='max-lg:hidden w-[44%] px-3 mt-[8%]'data-aos="zoom-in-up">
                <img className='w-[20%] ' src={chart}></img>
                <h1 className=' text-[35px] text-[#FFFFFF] font-extrabold'>Total Compensation<br></br>Insights</h1>
                <p className='text-[16px] text-[#FFFFFF] mt-3'>Compare your own compensation data with the market<br></br> and gather insights on what talent is earning and wants to<br></br> earn. You get to the most important data, fast and easy in<br></br> purpose build dashboards. Explore a range of<br></br> comprehensive data points: base salary, bonus, equity,<br></br> experience level, gender and more. <br></br>We help you compare apples with apples, levelling and identify pay-gaps.</p>
            </div>
            {/*mobile view */} 
            <div className='w-[44%] sm:hidden px-3 mt-[8%] max-lg:w-auto max-lg:p-0'>
                <img className='w-[20%] max-lg:w-[35%] ' src={chart}></img>
                <h1 className=' text-[35px] text-[#FFFFFF] font-bold mt-3 max-lg:text-[18px]'>Total Compensation Insights</h1>
                <div><p className='text-[16px] text-[#FFFFFF] mt-3 max-lg:text-[12px]'>Compare your own compensation data with<br></br> the market and gather insights on what talent<br></br> is earning and wants to earn.</p><p className='max-lg:text-[14px] max-lg:text-[#ffffff] max-lg:mt-[7%]'>We help you compare apples with apples, levelling and identify pay-gaps.</p></div>
            </div>
            {/*-- */}
        </div>
        <div className=' w-[60%] max-lg:px-3 sm:hidden max-lg:w-full'>
                <img className='w-[90%] max-lg:w-full' src={analysis}></img>
        </div>
        <div className=' mt-[5%] pt-[1.5rem] px-[5rem] flex justify-between max-lg:hidden max-lg:grid max-lg:w-full max-lg:px-10'>
            <div className=' w-[45%] max-lg:hidden mt-[10%] align-right relative text-right culturewriteup-tabletdesign' data-aos="fade-right">
                 <img className='w-[20%] ml-[80%]' src={charts}></img>
                <h1  className=' text-[35px] text-[#FFFFFF] font-extrabold'>Company Culture Insights</h1>
                <p className='text-[16px] text-[#FFFFFF] mt-3'>Validate how in and outside talent is thinking about your<br></br> company culture and receive social proof. Compare your<br></br> company data with competitors and use simple dashboards<br></br>  to customize and filter data the way you want. Drill down and<br></br> compare competitors by department, gender, employee<br></br> engagement and more. We help you to build social proof in the<br></br> market.</p>
            </div>
            <div className='w-[50%] max-lg:hidden px-3 relative cultureimg-tabletdesign'data-aos="zoom-in-up" >
                <img className='h-[100%]' src={Manalysis}></img>
            </div>
        </div>
        {/*mobile view */}
        <div className=' w-[45%] sm:hidden mt-[10%] max-lg:w-auto max-lg:px-10' data-aos="fade-right">
                 <img className='w-[20%] max-lg:w-[30%]' src={charts}></img>
                <h1  className='text-[35px] text-[#FFFFFF] font-bold mt-5 max-lg:text-[20px]'>Company Culture Insights</h1>
                <div><p className=' text-[#FFFFFF] mt-3 max-lg:text-[12px]'>Validate how in and outside talent is thinking
                    <br></br>about your company culture and receive
                    <br></br>social proof. Compare your company data
                    <br></br>with competitors and use simple dashboards
                    <br></br>to customize and filter data the way you want.</p>
                    <p className='mt-[5%] max-lg:text-[12px] text-[#ffffff]'>Drill down and compare competitors by
                    <br></br>department, gender, employee engagement
                    <br></br>and more. We help you to build social proof
                    <br></br>in the market.</p>
                </div>
            </div>
            <div className=' w-[60%] sm:hidden max-lg:w-full'data-aos="zoom-in-up" >
                <img className='w-[90%] max-lg:w-full' src={Manalysis}></img>
            </div> 
            {/*-- */}   
        <div className='ml-[10rem] max-lg:hidden mt-[15%] max-lg:ml-[0%] max-lg:px-10 max-lg:text-center'>
            <p className='text-white font-extrabold text-[40px] max-lg:text-[29px] win-tabdesign'>Win a spot on our whitelist or<br></br> purchase life-time access to<br></br> all our basic features</p>
        </div>
         {/*mobile view */}
         <div className='ml-[10rem] sm:hidden mt-[15%] max-lg:ml-[0%] max-lg:px-10 max-lg:text-center'>
            <p className='text-white font-bold text-[40px] max-lg:text-[18px]'>Win a spot on our whitelist<br></br> or purchase life-time access<br></br> to all our basic features</p>
        </div>
         {/*-- */}
        <Signup/>
        <div className='mt-[30%] border-b-2 px-[5rem] flex flex-col max-lg:w-full max-lg:px-2 seehow-tabletdesign'>
            <div className='text-center max-lg:w-full max-lg:px-4'>
                <h1 className='text-[#FFFFFF] text-[35px] font-poppin font-extrabold max-lg:text-[25px]'>See how it works</h1>
                <p className='text-[#FFFFFF] max-lg:hidden text-[20px] mt-4 max-lg:text-[12px] aimtocreate-tabdesign'>Metaworks aims to create a transparent and equitable talent market<br></br> through community backed products.</p>
                <p className='text-[#FFFFFF] sm:hidden text-[20px] mt-4 max-lg:text-[12px]'>Metaworks aims to create a transparent<br></br> and equitable talent market through community<br></br> backed products.</p>
            </div>
            <div className=' mt-[5%] max-lg:hidden'>
                <div className='flex justify-between max-lg:grid max-lg:w-full'>
                    <div className='mt-[5%] border-2 border-transparent py-8 px-8 w-[37%] flex flex-col h-[37%] bg-[#FDF9F9] rounded-[30px] relative max-lg:w-full whymetaworks-tabdesign'data-aos="flip-left">
                        <h1 className='text-[#000000] text-[27px] font-bold'>Why talent joins Metaworks</h1>
                        <p className=' text-[#000000] text-[18px] mt-2 leading-7'>Talent joins Metaworks because they want<br></br>to receive compensation and company<br></br>culture insights, to find jobs, connect with<br></br>peers, watch content or earn rewards.</p>
                    </div>
                    <div className='w-30% px-4 max-lg:hidden onetwo-tabdesign'>
                        <img src={oneTwo} className='h-[50%] mt-[100px]'></img>
                    </div>
                    <div className='mt-[23%] border-2 border-transparent py-5 px-7 w-[37%] flex flex-col h-[42%] bg-[#FDF9F9] rounded-[30px] relative whymetaworks-tabdesign'data-aos="flip-left">
                        <h1 className='text-[#000000] text-[27px] font-bold'>Privacy</h1>
                        <p className=' text-[#000000] text-[20px] mt-2 leading-7'>Talent joins Metaworks fully anonymous <br></br>and are required to share insights before<br></br>they can access insights themselves.</p>
                        <p className=' text-[#000000] text-[20px] mt-5'>Shared insights are signed on-chain <br></br>to validate authenticity.</p>
                    </div>
                </div>
            </div>
            {/*mobile page*/}
            <div className=' mt-[5%] max-lg:w-full sm:hidden'>
                <div className='flex justify-between max-lg:grid max-lg:w-full max-lg:p-0'>
                    <div className='max-lg:flex max-lg:w-auto max-lg:justify-evenly'>
                        <div className='mt-[5%] border-2 border-transparent py-8 px-8 w-[40%] flex flex-col h-[40%] bg-[#FDF9F9] rounded-[30px] relative max-lg:w-[86%] max-lg:h-auto max-lg:px-6'>
                            <h1 className='text-[#000000] text-[27px] font-bold max-lg:text-[14px] max-lg:w-full'>Why talent joins Metaworks</h1>
                            <p className=' text-[#000000] text-[20px] mt-2 leading-5 max-lg:text-[14px] max-lg:mb-6'>Talent joins Metaworks because<br></br> they wants to receive<br></br> compensation and company<br></br>culture insights, to find jobs,<br></br> connect with peers, watch<br></br> content or earn rewards.</p>
                        </div>
                        <div className='max-lg:w-[15%] max-lg:mt-5 max-lg:ml-3'>
                            <img className='w-full' src={num}></img>
                        </div>
                    </div>
                    <div className='max-lg:flex max-lg:w-auto max-lg:justify-evenly max-lg:mt-6'>
                        <div className='max-lg:w-[15%] max-lg:mt-5'>
                            <img className='w-full' src={numtwo}></img>
                        </div>
                        <div className='mt-[5%] border-2 border-transparent py-8 px-8 w-[40%] flex flex-col h-[40%] bg-[#FDF9F9] rounded-[30px] relative max-lg:w-[86%] max-lg:h-auto max-lg:px-6 max-lg:ml-3'>
                            <h1 className='text-[#000000] text-[27px] font-bold max-lg:text-[17px]'>Privacy</h1>
                            <p className=' text-[#000000] text-[20px] mt-2 leading-5 max-lg:text-[15px]'>Talent joins Metaworks fully<br></br> anonymous and are required to<br></br> share insights before they can<br></br> access insights themselves.</p>
                            <p className=' text-[#000000] text-[20px] mt-2 leading-5 max-lg:text-[15px] max-lg:mb-6'>Shared insights are signed<br></br> on-chain to validate authenticity.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*--*/}
            <div className='mt-[13%] text-center max-lg:w-full' data-aos="zoom-in">
                <h1 className='text-[29px] font-bold max-md:hidden text-[#FDF9F9]'>Join our mission to revolutionize <br></br>the talent market </h1>
                <h1 className='text-[27px] sm:hidden text-[#FDF9F9]'>Join our mission to<br></br> revolutionize the<br></br> talent market </h1>
            </div>
            <div className='mt-[19%] grid max-lg:hidden data-tabdesign' data-aos="zoom-in">
                <div className=' text-center'data-aos="zoom-in">
                    <h1 className='text-[29px] text-[#FDF9F9]'>Your data is protected</h1>
                </div>
                <div className='mt-[5%] flex justify-between'>
                    <div className='text-right w-[50%] leading-[24px]' >
                        <h3 className='text-[#FEF9F9] text-[25px] font-semibold'>Anonymous</h3>
                        <p className='text-[#FEF9F9] text-[15px] mt-2 aggregate-tabdesign'>We aggregate and anonymize data so<br></br>it can’t be linked back to an individual talent </p>
                    </div>
                    <div className='w-[40%] ml-5 mr-5 mt-2 '>
                        <img src={protect} className=''></img>
                    </div>
                    <div className=' w-[50%] leading-[24px]'>
                        <h3 className='text-[#FEF9F9] text-[25px] font-semibold'>Encryption</h3>
                        <p className='text-[#FEF9F9] text-[15px] mt-2'>All talent and customer data is<br></br>automatically encrypted </p>
                    </div>
                </div>
            </div>
            <div className=' flex justify-between mt-[10%] mb-[4%] max-lg:grid max-lg:w-full max-lg:justify-center max-lg:mt-[18%] max-lg:mb-[19%] ready-tabdesign' data-aos="zoom-in">
                <div className='max-lg:text-center'>
                    <h1 className='text-[#EFF5F6] text-[29px] font-extrabold'>Ready to get<br></br> Started ?</h1>
                </div>
                <div className=' max-lg:mt-5 redstart-tabdesign'>
                <HashLink to='#signup' smooth><button className=' bg-[#3989E8] w-[220px] h-[5rem] rounded-lg text-center text-[#ffffff] text-[27px] cursor-pointer relative ease-in-out'><div class="absolute inset-0 w-0 bg-[#3989E8] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                   <p>Start Now!</p>
                </button></HashLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Content