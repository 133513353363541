import React from 'react'
import Content from './Content'
import HeroPage from './HeroPage'
import Signup from './Signup'
import Footer from "./Footer";

const LandingPage = () => {
  return (
    <div>
        <HeroPage/>
        <Content/>
        <Footer/>
    </div>
  )
}

export default LandingPage