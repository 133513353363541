import React from 'react'
import axios from 'axios';
import logo from "./images/logo.png";
import { useState } from "react";
import { setUserId } from '@firebase/analytics';
const BlankPage = () => {
  return (
    <div className='grid place-items-center w-[100%] h-[100vh]'>
        <div class='meta-works-blink'>
            <img src={logo} className=' w-[30%]'></img>
        </div>
    </div>
  )
}

export default BlankPage