import React from 'react'
import moon from "./images/moon.png"
import limbo from "./images/Limbo.png"
import love from "./images/love.png"
import check from "./images/check.svg";
import { Link } from "react-router-dom";
import tick from "./images/tick.svg";
import arobot from "./images/Group 469.png"
const Signup = () => {
  return (
    <div id='signup'>
        <div className='part-four flex max-sm:w-full max-sm:hidden max-sm:h-auto' >
            <div className='w-[44%] max-sm:grid '>
                <div className='px-8 mt-5'>
                    <p className='text-[20px] text-[#ffffff] max-lg:text-[15px]'>Signing up will take 1 minute</p>
                </div>
                <div className='mt-[8%] shadow-lg shadow-blue-500/50 border-2 border-blue-400 px-10 py-12 bg-[#3989E8] rounded-lg'>
                    <p className='text-md text-[#ffffff]'>Option 1</p> 
                    <h1 className='text-[#ffffff] text-[19px] font-bold mt-[5%]'>Apply and win free early access<br></br>(max 30 spots, 17 still available)</h1>
                    <p className='text-[#ffffff] mt-[5%]'>We’re opening limited spots.  Increase your winning chances by:</p>
                    <div className='flex mt-[5%]'>
                        <img src={check} className='w-[7%]'></img>
                        <p className='text-[#ffffff] text-[16px] ml-3'>Following Metaworks on LinkedIn or Twitter  </p>
                    </div>
                    <div className='flex mt-[5%]'>
                        <img src={check} className='w-[7%]'></img>
                        <p className='text-[#ffffff] text-[16px] ml-3'>Re-post our announcement on LinkedIn or Twitter</p>
                    </div>
                    <div className='mt-11 mb-[4%]'>
                        <Link to='/option1'>
                            <button className=' group bg-[#1F3450] overflow-hidden w-[140px] h-14 rounded-full text-center text-[#FFFFFF] hover:text-[#1E1E1E] text-lg cursor-pointer relative ease-in-out font-poppin font-bold'>
                                <div class="absolute inset-0 w-0 bg-[#ffffff] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                                <p className='relative '>Sign up</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='w-[50%] ml-9 h-[60vh]'>
                <div className=' flex justify-center mt-[-39%] h-[70%] max-lg:h-[35%] '>
                <img src={moon} className='w-[30%] mr-[36%] mt-7 absolute'></img>
                <img src={limbo} className='w-[21%] absolute z-10'></img>
                <img src={love} className='w-[30%] ml-[36%] mt-4 absolute'></img>
                </div>
                <div className='border-2 bg-[#EFF5F6] z-50 absolute shadow-lg shadow-blue-500/50 px-10 py-5 rounded-lg w-[46%]'>
                <p className='text-md text-[#1E1E1E] font-bold'>Option 2</p> 
                    <h1 className=' text-[19px] font-extrabold mt-[5%]'>€450</h1> 
                    <h1 className='text-[19px] font-semibold'>Purchase a guaranteed spot and benefit<br></br>from life-long access to</h1>
                    <p className='text-[#1E1E1E] mt-[5%]'>We’re opening limited spots.  Increase your winning chances by:</p>
                    <div className='flex mt-[5%]'>
                        <img src={tick} className='w-[7%]'></img>
                        <p className='text-[#1E1E1E] text-[16px] ml-3'>Access to Metaworks Insights including:<br></br>benchmarks, powerful filters & recommendations </p>
                    </div>
                    <div className='flex mt-[4%]'>
                        <img src={tick} className='w-[7%]'></img>
                        <p className='text-[#1E1E1E] text-[16px] ml-3'>Quarterly workshops from top HR industry leaders </p>
                    </div>
                    <div className='flex mt-[4%]'>
                        <img src={tick} className='w-[7%]'></img>
                        <p className='text-[#1E1E1E] text-[16px] ml-3'>Direct access to the Metaworks community and <br></br>share up to 5 jobs per months free of charge </p>
                    </div>
                    <div className='flex mt-[4%]'>
                        <img src={tick} className='w-[7%]'></img>
                        <p className='text-[#1E1E1E] text-[16px] ml-3'>Discount on future product releases </p>
                    </div>
                    <div className='flex mt-[4%]'>
                        <img src={tick} className='w-[7%]'></img>
                        <p className='text-[#1E1E1E] text-[16px] ml-3'>Influence and  shape the future of <br></br>Metaworks</p>
                    </div>
                    <div className='mt-9 mb-[5%]'>
                        <Link to='/option2'>
                            <button className=' group bg-[#ffffff] overflow-hidden w-[140px] h-14 border border-[#3989E8] rounded-full text-center text-[#1E1E1E] text-lg cursor-pointer relative ease-in-out font-poppin font-bold hover:text-white'>
                                <div class="absolute inset-0 w-0 bg-[#3989E8] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                                <p className='relative '>Sign up</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='sm:hidden max-sm:mt-7 max-sm:w-full max-sm:text-center'>
            <p className='text-[20px] text-[#ffffff] max-sm:text-[19px]'>Signing up will take 1 minute</p>
        </div>
        {/*mobile view*/}
        <div className='part-ref sm:hidden max-sm:px-5 max-sm:justify-content max-sm:grid '>
            <div className=' flex justify-center mt-[-35%] h-[22vh] w-full '>
                <img src={arobot} className='w-[93%] h-[30%] absolute'></img>            
            </div>
            {/*option */}
            <div className='w-[44%] max-sm:grid max-sm:w-full sm:hidden justify-center '>
                <div className='shadow-lg z-40 shadow-blue-500/50 border-2 border-blue-400 px-5 py-12 max-sm:ml-[5%] bg-[#3989E8] rounded-lg max-sm:w-[90%]'>
                    <p className='text-md text-[#ffffff]'>Option 1</p> 
                    <h1 className='text-[#ffffff] text-[19px] font-bold mt-[5%]'>Apply and win free early access<br></br><span className='text-[14px]'>(max 30 spots, 17 still available)</span></h1>
                    <p className='text-[#ffffff] mt-[5%] text-[14px]'>We’re opening limited spots.  Increase your winning chances by:</p>
                    <div className='flex mt-[5%]'>
                        <img src={check} className='w-[10%]'></img>
                        <p className='text-[#ffffff] text-[13px] mt-3 ml-3'>Following Metaworks on LinkedIn or Twitter  </p>
                    </div>
                    <div className='flex mt-[5%]'>
                        <img src={check} className='w-[10%]'></img>
                        <p className='text-[#ffffff] text-[13px] ml-3 mt-3'>Re-post our announcement on LinkedIn or Twitter</p>
                    </div>
                    <div className='mt-11 mb-[4%]'>
                        <Link to='/option1'>
                            <button className=' group bg-[#1F3450] overflow-hidden w-[140px] h-14 rounded-full text-center text-[#FFFFFF] hover:text-[#1E1E1E] text-lg cursor-pointer relative ease-in-out font-poppin font-bold'>
                                <div class="absolute inset-0 w-0 bg-[#ffffff] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                                <p className='relative '>Sign up</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='border-2 sm:hidden bg-[#EFF5F6] z-50 mt-6 shadow-lg shadow-blue-500/50 px-6 py-5 rounded-lg w-[46%] max-sm:w-full'>
                <p className='text-md text-[#1E1E1E] font-bold'>Option 2</p> 
                    <h1 className='text-[#1E1E1E] text-[19px] font-extrabold mt-[5%]'>€450</h1>
                    <h1 className='text-[#1E1E1E] text-[13px] '>Purchase a guaranteed spot and benefit<br></br>from life-long access to</h1>
                    <p className='text-[#1E1E1E] mt-[5%] text-[13px]'>We’re opening limited spots.  Increase your winning chances by:</p>
                    <div className='flex mt-[5%]'>
                        <img src={tick} className=' mr-2 mt-[-2rem]'></img>
                        <p className='text-[#1E1E1E] text-[13px]'>Access to Metaworks Insights<br></br> including:benchmarks, powerful filters & recommendations </p>
                    </div>
                    <div className='flex mt-[4%]'>
                        <img src={tick} className=' mr-2 mt-[-2rem]'></img>
                        <p className='text-[#1E1E1E] text-[13px]'>Quarterly workshops from top HR industry leaders </p>
                    </div>
                    <div className='flex mt-[4%]'>
                        <img src={tick} className=' mr-2 mt-[-2rem]'></img>
                        <p className='text-[#1E1E1E] text-[13px]'>Direct access to the Metaworks<br></br> community and share up to 5 jobs per months free of charge </p>
                    </div>
                    <div className='flex mt-[4%]'>
                        <img src={tick} className=' mr-2 mt-[-2rem]'></img>
                        <p className='text-[#1E1E1E] text-[13px]'>Discount on future product releases </p>
                    </div>
                    <div className='flex mt-[4%]'>
                        <img src={tick} className=' mr-2 mt-[-2rem]'></img>
                        <p className='text-[#1E1E1E] text-[13px]'>Influence and  shape the future of <br></br>Metaworks</p>
                    </div>
                    <div className='mt-9 mb-[5%]'>
                        <Link to='/option2'>
                            <button className=' group bg-[#ffffff] overflow-hidden w-[140px] h-14 border border-[#3989E8] rounded-full text-center text-[#1E1E1E] text-lg cursor-pointer relative ease-in-out font-poppin font-bold hover:text-white'>
                                <div class="absolute inset-0 w-0 bg-[#3989E8] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                                <p className='relative '>Sign up</p>
                            </button>
                        </Link>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Signup