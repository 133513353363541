import React from 'react'
import logo from './images/logo.png'
import { BiCopyright } from 'react-icons/bi';
import { FaTwitter } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import "@fontsource/poppins";
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
const footer = () => {
    let icon = {width:"23px",color:"white",marginTop:"7%",marginLeft:"5%"}
    let iconic ={width:"23px",color:"white",marginTop:"4%"} 
    let icons = {width:"23px",color:"blue",fontSize:"50px"} 
  return (
    <div className='flex justify-between px-[5rem] mt-[10%] max-sm:grid h-[65vh] max-sm:px-6 max-sm:justify-evenly max-sm:mb-[10%] max-md:mb-[10%]'>
        <div className='w-[50%] h-20 max-sm:w-full max-sm:h-auto'>
            <div className='metawork-tabletdesign'>
                <img src={logo} className='w-[48%]'></img>
            </div>
            <div className='mt-9 flex justify-between'>
                <div className='w-[50%] leading-[45px]'>
                    <Link to='/blank'><h1 className='text-[25px] text-[#FFFFFF] max-sm:text-[20px] cursor-pointer'>Company</h1></Link>
                    <Link to='/blank'><div className=' border-2 border-[#3989E8] cursor-pointer rounded-full mt-[-7px] w-[17%]'></div></Link>
                    <Link to='/blank'><p className='text-[15px] text-[#FFFFFF] cursor-pointer'>About</p></Link>
                    <Link to='/blank'><p className='text-[15px] text-[#FFFFFF] cursor-pointer'>How it works</p></Link>
                    <Link to='/blank'><p className='text-[15px] text-[#FFFFFF] cursor-pointer'>FAQ</p></Link>
                </div>
                <div className='w-[50%] leading-[45px] max-md:ml-[10%]'>
                    <Link to='/blank'><h1 className='text-[25px] text-[#FFFFFF] max-sm:text-[20px] cursor-pointer'>Community</h1></Link>
                    <Link to='/blank'><div className=' border-2 border-[#3989E8] cursor-pointer rounded-full mt-[-9px] w-[17%]'></div></Link>
                    <a target='_blank' href='https://discord.gg/GV76SVMW'><p className='text-[15px] text-[#FFFFFF] cursor-pointer'>Discord</p></a>
                    <a target='_blank' href='https://twitter.com/metaworks_dao'><p className='text-[15px] text-[#FFFFFF] cursor-pointer'>Twitter</p></a>
               </div>
            </div>
            <div className='mt-[10%] copyright-tablet flex max-sm:hidden'>
                <h2 className='flex text-[#ffffff] text-[20px]'>Copyright<BiCopyright style={icon}/></h2>
                <h2 className='ml-2 text-[#ffffff] text-[20px]'>2023 Metaworks. All rights reserved.</h2>
            </div>
        </div>
        <div className='w-[20%] leading-loose max-sm:mt-[10%] max-sm:w-[60%] max-md:w-[25%]'>
            <div className='flex mt-3 w-[68%] justify-between twitter-tabletdesign'>
                <a target='_blank' href='https://twitter.com/metaworks_dao'><FaTwitter className='text-4xl text-[#3989E8] cursor-pointer duration-150 hover:mt-[-5%]' size={32}/></a>
                <a target='_blank' href='https://discord.gg/GV76SVMW'><FaDiscord className='text-4xl text-[#3989E8] cursor-pointer duration-150 hover:mt-[-5%]' size={32}/></a>
                <a target='_blank' href='https://www.linkedin.com/company/0xmetaworks/'><AiFillLinkedin className='text-4xl text-[#3989E8] cursor-pointer duration-150 hover:mt-[-5%]' size={32} /></a>
            </div>
        </div>
        <div className='mt-[10%] flex sm:hidden max-sm:w-full'>
            <h2 className='flex text-[#ffffff] text-[20px] max-sm:text-[13px]'>Copyright<BiCopyright style={iconic}/></h2>
            <h2 className='ml-0 text-[#ffffff] text-[20px] max-sm:text-[13px]'>2023 Metaworks. All rights reserved.</h2>
        </div>
    </div>
  )
}

export default footer