import { getDatabase } from "firebase/database"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyBN-KZxZ7rO2yhGStU08cttmso8390cZ8c",
    authDomain: "works-8bf3d.firebaseapp.com",
    databaseURL: "https://works-8bf3d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "works-8bf3d",
    storageBucket: "works-8bf3d.appspot.com",
    messagingSenderId: "638032010114",
    appId: "1:638032010114:web:1fe5243c408ca8cc2d4d9b",
    measurementId: "G-03XY07EKLT"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);