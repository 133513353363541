import React from 'react'
import { Link } from 'react-router-dom';
import logo  from "./images/logo.png";
import toolslide from "./images/toolslide.png"
import send from "./images/Send.svg"
const Header = () => {
  return (
    <div className='flex justify-between max-sm:grid max-sm:justify-center'>
      <div className='w-[25%] pt-4 max-sm:hidden cursor-pointer max-md:w-[35%]'>
        <Link to='/'><button className=' cursor-pointer'><img src={logo} className='w-full'></img></button></Link>
      </div>
      <div className='w-[330px] max-sm:hidden max-md:w-[280px]'>
        <div className=' max-sm:w-[250px] max-sm:ml-2  max-sm:mt-4 max-sm:h-auto px-4 py-3 w-[330px] max-md:w-[290px] h-[80px] border-2 border-transparent shadow-lg shadow-blue-500/50 rounded-md bg-[#3989E8]'>
          <p className='text-white text-[12px] max-sm:text-[10px]'>Win a free spot on our whitelist</p>
          <p className='text-white text-[14px] max-sm:text-[12px]'>(max 30 spots, 17 still available)</p>
          <div className='w-[100%] max-sm:w-full bg-white h-2 mt-2 rounded-full flex justify-between'>
           <img src={toolslide}></img>
          </div>
        </div>
      </div>
      {/*mobile */}
      <div className='w-[100%] pt-4 sm:hidden'>
        <Link to='/'><img src={logo} className='w-[58%] mx-auto cursor-pointer'></img></Link>
      </div>
        <div className='w-full flex justify-around mt-2 h-auto sm:hidden'>
          <div className=' max-sm:w-[250px] max-sm:mt-4 max-sm:h-auto px-4 py-3 h-[80px] border-2 border-transparent shadow-lg shadow-blue-500/50 rounded-md bg-[#3989E8]'>
            <p className='text-white text-[12px] max-sm:text-[10px]'>Win a free spot on our whitelist</p>
            <p className='text-white text-[14px] max-sm:text-[12px]'>(max 30 spots, 17 still available)</p>
            <div className='w-[100%] max-sm:w-full bg-white h-2 mt-2 rounded-full flex justify-between'>
             <img src={toolslide}></img>
            </div>
          </div>
          <div className='sm:hidden'><img src={send} className='w-[10%] absolute sm:hidden'></img></div>
       </div>
    </div>
  )
}
/*<div className='flex max-sm:grid max-sm:w-full max-sm:justify-center'>
        <Link to='/'><div className='max-sm:w-full max-sm:flex cursor-pointer max-sm:justify-center'>
                    <img src={logo} className='w-[33%] mt-3 max-sm:w-[52%]'></img>
                </div></Link>       
      </div>
<div className='max-sm:flex max-sm:justify-evenly max-sm:w-auto max-sm:mt-4'>
                  <div className=' max-sm:w-[250px] max-sm:ml-2  max-sm:mt-4 max-sm:h-auto px-4 py-3 w-[330px] h-[80px] border-2 border-transparent shadow-lg shadow-blue-500/50 rounded-md bg-[#3989E8]'>
                      
                      
                  </div>
                  <div className='sm:hidden'><img src={send} className='w-[10%] absolute sm:hidden'></img></div>
                </div> */
export default Header