import React, {useEffect } from 'react'
import logo  from "./images/logo.png";
import heroimage from "./images/Analysis.png"
import search from "./images/Search.svg"
import robot from './images/robot.png'
import Aos from 'aos';
import 'aos/dist/aos.css';
import people from './images/icon-people.svg'
import cash from './images/icon-cash.svg'
import time from './images/icon-time.svg'
import play from './images/icon-play.svg'
import analysis from './images/Analysis2.png'
import Manalysis from './images/Analysis3.png'
import chart from './images/Chart.svg'
import charts from './images/chart1.svg'
import whitelist from "./images/whitelist.png";
import meta from "./images/social-meta.svg";
import rocket from "./images/social-a.svg";
import volvo from "./images/social-volvo.svg";
import upvest from "./images/social-upvest.svg";
import ivum from "./images/social-ivum.svg";
import booking from "./images/Group.png";
import send from "./images/Send.svg"
import toolslide from "./images/toolslide.svg"
import "@fontsource/poppins";
import "flowbite";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Header from './Header';
import pa from "./images/phoneanalysis.png";


const HeroPage = () => {
useEffect(() => {
    Aos.init({duration:2000,once:true});
  })  
return (
    <div className='min-w-fit'>
        <div className='header max-sm:grid max-sm:w-full max-sm:justify-center max-sm:px-8'>
            <Header/>
            <div className='hero max-sm:hidden min-w-fit'>
                <div className=' w-[45%]'>
                    <div className='flex justify-between mt-9 max-sm:text-center realtime_text'>
                        <h1 className=' font-poppin font-extrabold text-[48px] text-[#FFFFFF] leading-normal max-sm:text-[50px]'>Real-time<br></br>company insights<br></br>backed by a <br></br>community of<br></br>100.000+ talents</h1>
                        <img src={send}  className=' max-sm:hidden absolute w-14 ml-[35%] top-36'></img>
                    </div>
                    <div className='border-t-2 border-[#82BBFF] w-[35%] shadow mt-2'></div>
                    <p className=' max-sm:hidden font-poppin text-[20px] mt-4 text-[#EFF5F6] get_real'>Get real-time access to compensation market data,<br></br>learn how talent is thinking about your brand and<br></br>receive social validation</p>
                    <p className=' max-sm:text-[3px] sm:hidden font-poppin mt-4 text-[#EFF5F6] text-md'>Get real-time access to compensation<br></br> market data,learn how talent is thinking<br></br> about your brand and receive social validation</p>
                    <div className='mt-7'>
                    <HashLink to='#signup' smooth><button className=' group bg-[#EFF5F6] overflow-hidden w-[140px] h-14 rounded-lg text-center text-[#0F0D24] text-lg cursor-pointer relative ease-in-out hover:shadow-lg hover:shadow-[#398938]-500/50 font-poppin font-bold hover:text-white
                        '><div class="absolute inset-0 w-0 bg-[#3989E8] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                        <p className='relative '>Sign up</p>
                        </button></HashLink>
                    </div>
                </div>
                <div className=' w-[45%] mt-[14%] max-sm:border'>
                    <img src={heroimage} className='w-full'></img>
                    <img src={search} className=' w-12 ml-[30%]'></img>
                </div>
            </div>
            {/* mobile page */}
            <div className='hero sm:hidden max-sm:flex max-sm:flex-col max-h-[640px]:'>
                <div className=' w-[50%] max-sm:w-[100%] mt-[14%]'>
                    <img src={pa} className='w-full  max-sm:block max-sm:ml-auto max-sm:mr-auto max-sm:w-[100%]'></img>
                    <img src={search} className=' w-12 ml-[15%] max-sm:w-[15%] max-sm:mt-[12%]'></img>
                </div>
                <div className=' w-[50%] max-sm:w-full '>
                    <div className='flex justify-between mt-9 max-sm:text-center '>
                        <h1 className=' font-poppin font-bold text-[45px] text-[#FFFFFF] leading-normal max-sm:w-[100%] max-sm:text-[25px] max-sm:font-bold'>Real-time<br></br>company insights<br></br>backed by a <br></br>community of<br></br>100.000+ talents</h1>
                    </div>
                    <div className='max-sm:w-100 max-sm:flex max-sm:justify-center'><div className='border-t-4 border-[#82BBFF] w-[50%] max-sm:w-[50%] max-sm:border-[#0F0D24] max-sm:border-3 max-sm:rounded-full shadow mt-2'></div></div>
                    <div className='w-full flex justify-center pr-3'><p className=' max-sm:text-[12px] font-poppin mt-4 text-[#EFF5F6] max-sm:w-[85%] max-sm:ml-[5%] max-sm:text-center'>Get real-time access to compensation<br></br> market data,learn how talent is thinking<br></br> about your brand and receive social validation</p></div>
                    <div className='mt-7 max-sm:flex max-sm:justify-center max-sm:w-full'>
                    <HashLink to='#signup' smooth><button className='mb-[20%] group bg-[#EFF5F6] overflow-hidden w-[140px] max-sm:w-[160px] h-14 rounded-lg text-center text-[#0F0D24] text-lg cursor-pointer relative ease-in-out hover:shadow-lg hover:shadow-[#398938]-500/50 font-poppin font-bold hover:text-white
                        '><div class="absolute inset-0 w-0 bg-[#3989E8] transition-all duration-[600ms] ease-in-out group-hover:w-full"></div>
                        <p className='relative '>Sign up</p>
                        </button></HashLink>
                    </div>
                </div>
                <div className='mt-[4%] max-sm:w-full max-sm:justify-center px-6 metawork_write'>
                    <h2 className='text-white text-[24px] text-center max-sm:text-[16px]'>Metaworks is brought to you by former talent from</h2>
                </div>
                <div className='w-full flex flex-col justify-center mb-[19%]'>
                <div>
                    <div className='w-[100%] mt-6 flex justify-between'>
                        <img src={meta} className='ml-auto mr-auto w-[18%]'></img>
                        <img src={rocket} className='ml-auto mr-auto w-[30%]'></img>
                        <img src={volvo} className='ml-auto mr-auto w-[20%]'></img>
                    </div>
                </div>
                <div className='w-[100%] mt-6 flex justify-between'>
                    <img src={upvest} className='ml-auto mr-auto w-[25%]'></img>
                    <img src={ivum} className='ml-auto mr-auto w-[25%]'></img>
                </div>
                <div className='w-[100%] mt-6 flex justify-between'>
                    <img src={booking}className='ml-auto mr-auto w-[29%]'></img>
                </div>
            </div>   
            </div>
            <div className='mt-[4%] max-sm:w-full max-sm:justify-center px-6 max-sm:hidden'>
                <h2 className='text-white text-[24px] text-center max-sm:text-[20px] max-lg:text-[15px]'>Metaworks is brought to you by former talent from</h2>
            </div>  
                <div className="w-full mt-[2%] max-sm:hidden h-[10vh]">
                    <div className=" m-auto overflow-x-hidden relative w-auto flex justify-between mt-4">
                        <div className="flex animate-marquee w-[100%] max-sm:w-full justify-evenly max-sm:border-2 max-sm:flex max-sm:justify-evenly max-sm:pr-[20%]">
                            <span className=""><img src={meta} className="w-[80px] h-auto mt-2 max-lg:w-[80px] max-md:w-[50px] max-md:mt-3"></img></span>
                            <span className=""><img src={rocket} className="w-[120px] h-auto mt-3 max-lg:w-[90px] max-md:w-[55px]"></img></span>
                            <span className=""><img src={upvest} className="w-[100px] h-auto mt-2 max-lg:w-[80px] max-md:w-[50px]"></img></span>
                            <span className=""><img src={volvo} className="w-[100px] h-auto mt-3 max-lg:w-[80px] max-md:w-[50px]"></img></span>
                            <span className=""><img src={ivum} className="w-[100px] h-auto mb-2 max-lg:w-[80px] max-md:w-[50px] max-md:mt-2 max-lg:mt-1"></img></span>
                            <span className=""><img src={booking} className="w-[100px] h-auto mt-2 max-lg:w-[92px] max-md:w-[50px] max-md:mt-3 max-lg:mt-3"></img></span>
                        </div>
                        <div className="absolute top-0 flex animate-marquee2 whitespace-nowrap max-sm:border-2 w-[100%] justify-evenly">
                            <span className=""><img src={meta} className="w-[90px] h-auto mt-2 max-lg:w-[80px] max-md:w-[50px] max-md:mt-3"></img></span>
                            <span className=""><img src={rocket} className="w-[120px] h-auto mt-3 max-lg:w-[90px] max-md:w-[55px]"></img></span>
                            <span className=""><img src={upvest} className="w-[100px] h-auto mt-2 max-lg:w-[80px] max-md:w-[50px]"></img></span>
                            <span className=""><img src={volvo} className="w-[100px] h-auto mt-3 max-lg:w-[80px] max-md:w-[50px]"></img></span>
                            <span className=""><img src={ivum} className="w-[100px] h-auto mb-2 max-lg:w-[80px] max-md:w-[50px] max-md:mt-2 max-lg:mt-1"></img></span>
                            <span className=""><img src={booking} className="w-[100px] h-auto mt-2 max-lg:w-[92px] max-md:w-[50px] max-md:mt-3 max-lg:mt-3"></img></span>
                        </div>
                    </div>
                </div>         
        </div>
    </div>
  )
}

export default HeroPage