import React, { useEffect } from 'react'
import modalcheck from "./images/Group 481.png";
import { Link } from 'react-router-dom';
const ModalPopUp = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = 'unset';
  },[])
  return (
    <div>
      <div className='modalparent'>
        <div className='w-[35%] max-lg:h-[83vh] max-sm:hidden py-5 px-5 bg-white text-center grid justify-center fixed top-[20%] right-0 max-sm:left-[15%] left-[30%] bottom-[20%] opacity-100 rounded-lg z-50'>
          <img src={modalcheck} className='w-[20%] mx-auto'></img>
          <h1 className='text-[#0F0D24] text-md font-bold mt-2 leading-10'>Sign Up<br></br>successful</h1>
          <p className='text-sm font-light mt-4 leading-normal'>Thanks for signing up.<br></br>Metaworks will reach out to you via email</p>
          <Link to='/'><button className=' bg-[#3989e9] cursor-pointer text-[#ffffff] text-sm border-2 mt-4 py-3 rounded-md flex justify-center w-full'>Return to Homepage</button></Link>
        </div>
        <div className='slide'>
          <div className='w-[35%] sm:hidden max-sm:w-[70%] h-auto py-10 px-3 bg-white text-center grid justify-center fixed top-[20%] right-0 max-sm:left-[15%] left-[30%] bottom-[20%] opacity-100 rounded-lg z-50'>
            <img src={modalcheck} className='w-[37%] mx-auto'></img>
            <h1 className='text-[#0F0D24] text-md font-bold mt-2 leading-10'>Sign Up<br></br>successful</h1>
            <p className='text-sm font-light mt-3 leading-6'>Thanks for signing up.<br></br>Metaworks will reach out to<br></br> you via email</p>
            <Link to='/'><div className='  bg-[#3989e9] cursor-pointer text-[#ffffff] text-sm border-2 mt-4 py-1 rounded-md flex justify-center'>Return to Homepage</div></Link>
          </div>
      </div>
        </div>
        
    </div>
  )
}

export default ModalPopUp