import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo  from "./images/logo.png";
import framepic from "./images/Framepic.png";
import meta from "./images/social-meta.svg";
import rocket from "./images/social-a.svg";
import volvo from "./images/social-volvo.svg";
import upvest from "./images/social-upvest.svg";
import ivum from "./images/social-ivum.svg";
import booking from "./images/social-booking.svg";
import Footer from './Footer';
import { HashLink } from "react-router-hash-link";
import {database} from './Firebase'
import {ref,push,child,update} from "firebase/database";
import ModalPopUp from './ModalPopUp';
const SubscribePage = () => {
        const [companyName, setCompanyName] = useState('');
        const [contactName, setContactName] = useState('');
        const [email, setEmail] = useState('');
        const [numberOfEmployee,setNumberOfEmployee] = useState('');
        const [visible, setVisible] = useState(false);
    const handleSubmit  = (e) => {
        console.log(companyName,contactName,email,numberOfEmployee);
        e.preventDefault();
        let obj = {
            companyName : companyName,
            contactName: contactName,
            email: email,
            numberOfEmployee: numberOfEmployee,
        }       
        const newPostKey = push(child(ref(database), 'posts')).key;
        const updates = {};
        setVisible(true)
        updates['/' + newPostKey] = obj
        setVisible(true)
        return update(ref(database), updates);
    }

  return (
    <div className='w-full'>
        {visible && (
        <ModalPopUp/>
       )
       }
        <div>
            <div className='w-[25%] pt-7 px-4 max-sm:hidden'>
                <Link to='/'><img src={logo} className='w-full cursor-pointer'></img></Link>
            </div>
            <div className='header-two max-sm:hidden'>
                <div className='flex justify-between mt-[10%]'>
                <div className='header-two-img w-[50%] h-auto 2xl:h-auto'>
                    <img src={framepic} className='w-[100%] mt-[-10%]'></img>
                </div>
                <div className='w-[43%] mt-[6%] absolute z-40 ml-[46%] max-lg:w-[50%] max-md:w-[50%]'>
                    <div className='absolute z-40 px-5 py-12 bg-white mr-[5px] w-[100%] rounded-2xl text-center 2xl:h-[75vh]'> 
                        <h3 className='text-[#717375] font-semibold max-md:text-[12px]'>Real-time company insights backed by a<br></br>community of 100.000+ talents</h3>
                        <p className='text-[15px] text-[#717375] font-light max-md:text-[11px]'>Please provide these few details to begin your process</p>
                        <div className='w-full px-5 py-5'>
                            <form  onSubmit={handleSubmit}>
                                <input type='text' id='CompanyName' placeholder="Company Name" onChange = {(e) => setCompanyName(e.target.value)} value={companyName} className='outline-none border' required></input>
                                <input type='text' id='ContactName' placeholder="Contact Name" onChange = {(e) => setContactName(e.target.value)} value={contactName} className='outline-none border mt-3' required></input>                                    
                                <input type='email' id='email' placeholder="Work Email" onChange = {(e) => setEmail(e.target.value)} value={email} className='outline-none border mt-3' required></input>
                                <input type='number' id='NumberOfEmployee' placeholder="Number of employees" onChange = {(e) => setNumberOfEmployee(e.target.value)} value={numberOfEmployee} className='outline-none border mt-3' required></input>
                                <p className='text-[15px] mt-[10%] max-md:text-[11px]'>Metaworks will reach out to you to process payment</p>
                                <button name="btnSend" className=' w-full bg-[#3989E8] rounded-md text-[#ffffff] py-4 mt-4 cursor-pointer'>Next!</button>{/* */}
                            </form>
                            <p className='text-[15px] mt-[6%] max-md:text-[8px]'>By signing up, you agree to our Terms of Use, Learn how we<br></br>collect your data in our Privacy Policy.</p>    
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className='mt-[15%] max-sm:hidden max-lg:mt-[50%] max-md:mt-[70%]'>
                     <h2 className='text-white text-center 2xl:text-[20px]'>Metaworks is brought to you by former talent from</h2>
                </div>  
                <div className="w-full mt-10 max-sm:hidden">
                   <div className="h-[10vh] m-auto overflow-x-hidden relative w-auto flex justify-between">
                            <div className="flex animate-marquee w-[100%] justify-between">
                                <span className=""><img src={meta} className="w-[80px] h-[10vh]"></img></span>
                                <span className=""><img src={rocket} className="w-[120px] h-[10vh] ml-[55px]"></img></span>
                                <span className=""><img src={upvest} className="w-[100px] h-[10vh] ml-[55px]"></img></span>
                                <span className=""><img src={volvo} className="w-[100px] h-[10vh] ml-[55px]"></img></span>
                                <span className=""><img src={ivum} className="w-[100px] h-[10vh] ml-[55px]"></img></span>
                                <span className=""><img src={booking} className="w-[100px] h-[10vh] ml-[55px]"></img></span>
                            </div>
                             <div className="absolute top-0 flex animate-marquee2 whitespace-nowrap w-[100%] ml-[70px] justify-between">
                                <span className=""><img src={meta} className="w-[90px] h-[10vh]"></img></span>
                                <span className=""><img src={rocket} className="w-[120px] h-[10vh] ml-[55px]"></img></span>
                                <span className=""><img src={upvest} className="w-[100px] h-[10vh] ml-[55px]"></img></span>
                                <span className=""><img src={volvo} className="w-[100px] h-[10vh] ml-[55px]"></img></span>
                                <span className=""><img src={ivum} className="w-[100px] h-[10vh] ml-[55px]"></img></span>
                                <span className=""><img src={booking} className="w-[100px] h-[10vh] ml-[55px]"></img></span>
                            </div>
                        </div>
                    </div> 
            {/* mobile screen */}
            <div className='sm:hidden'>
                <div className='pt-5'>
                <div className='pt-5'>
                    <Link to='/'><div className='max-sm:w-full cursor-pointer'>
                        <img src={logo} className='w-[50%] ml-auto mr-auto'></img>
                    </div></Link>
                </div>
                <div className='flex justify-between mt-[10%] max-lg:block max-lg:mb-[30%] sm:hidden max-sm:mt-[50%] px-1'>
                    <div className='header-two-img w-[50%] max-sm:w-full max-sm:h-auto max-sm:bg-gradient-to-b from-[#3989E8] to-[#0F0D24] opacity-[.4] 500px'>
                        <img src={framepic} className='w-[100%] mt-[-10%] bg-gradient-to-t from-black to-[#3989E8]'></img>
                    </div>
                    <div className='max-sm:w-full max-sm:flex max-sm:justify-center max-sm:h-auto sm:hidden'>
                       <div className='w-[43%] mt-[6%] z-40 absolute max-sm:w-[90%] max-sm:mt-[-35rem] max-sm:h-auto'>
                          <div className='absolute z-40 px-4 py-12 bg-white mr-[5px] w-[100%] rounded-2xl text-center '> 
                             <h3 className='text-[#717375] font-semibold text-[13px]'>Real-time company insights backed by a<br></br>community of 100.000+ talents</h3>
                                <p className='text-[11px] text-[#717375] font-light'>Please provide these few details to begin your process</p>
                            <div className='w-full px-5 py-5 max-sm:px-1'>
                                <form  onSubmit={handleSubmit}>
                                        <input type='text' id='CompanyName' placeholder="Company Name" onChange = {(e) => setCompanyName(e.target.value)} value={companyName} className='outline-none border' required></input>
                                        <input type='text' id='ContactName' placeholder="Contact Name" onChange = {(e) => setContactName(e.target.value)} value={contactName} className='outline-none border mt-3' required></input>                                    
                                        <input type='email' id='email' placeholder="Work Email" onChange = {(e) => setEmail(e.target.value)} value={email} className='outline-none border mt-3' required></input>
                                        <input type='number' id='NumberOfEmployee' placeholder="Number of employees" onChange = {(e) => setNumberOfEmployee(e.target.value)} value={numberOfEmployee} className='outline-none border mt-3' required></input>
                                        <p className='text-[10px] mt-[10%]'>Metaworks will reach out to you to process payment</p>
                                        <button name="btnSend" className=' w-full rounded-md bg-[#3989E8] text-[#ffffff] py-4 mt-4 cursor-pointer'>Next!</button>
                                        <div className='w-full'><p className='text-[15px] mt-[6%] max-sm:text-[9px] max-sm:w-full'>By signing up, you agree to our Terms of Use, Learn how we<br></br>collect your data in our Privacy Policy.</p></div>
                                </form>
                         </div>
                          </div>
                        </div>
                    </div>    
                </div>
             </div>
            </div>
        <Footer/>
    </div>
  )
}

export default SubscribePage
